import { AspectRatio as ApiAspectRatio } from "@switcherstudio/switcher-api-client";

export type AspectRatioObject = ApiAspectRatio;

export enum AssetType {
    image = "image",
    lowerthird = "lowerthird",
    video = "video",
    audio = "audio"
}

export enum AspectRatio {
    horizontal = "horizontal",
    vertical = "vertical",
    auto = "auto"
}

export type AspectRatioMap = {
    [key: string]: AspectRatioObject;
};

export type Thumbnail = {
    dataURL: string;
    width: number;
    height: number;
    art: any;
};

export type FileItem = {
    name: string;
    file: File;
    thumbnail?: Thumbnail;
    assetType?: AssetType;
    fileProps?: FileProps;
    aspectRatios?: AspectRatioObject[];
    videoRef?: any;
};

export type FileProps = {
    dataStr?: string;
    enableAudio?: boolean;
    endOnBlack?: boolean;
    audioOnly?: boolean;
    tag?: string;
    thumbnailTag?: string;
};

export interface FileWithMetadata extends Omit<FileItem, "name"> {
    metadata: Blob;
    mid: string;
    imageMid: string;
    fileProps?: FileProps;
}

export type FilePropsKey = "enableAudio" | "endOnBlack" | "audioOnly";

export type XMPMeta = {
    xmpmeta: {
        RDF: {
            Description: {
                "_xmlns:swi": string;
                "_rdf:about": string;
                "_swi:MediaID": string;
                __prefix: string;
                MediaID: {
                    __prefix: string;
                    __text: string;
                };
            };
            "_xmlns:rdf": string;
            __prefix: string;
        };
        "_xmlns:x": string;
        "_x:xmptk": string;
        __prefix: string;
    };
};
