import { VideoRoom } from "@switcherstudio/switcher-api-client";
import { useState, useEffect } from "react";
import { client } from "api/client";
import { useIsMountedRef } from "./useIsMountedRef";
import rollbar from "helpers/rollbar";

export const useGetRoom = (
    userId: string
): { loading: boolean; room: VideoRoom } => {
    const isMountedRef = useIsMountedRef();

    const [room, setRoom] = useState<VideoRoom>();

    useEffect(() => {
        const get = async () => {
            try {
                const rooms = await client.twilio_GetRooms(userId || "");
                if (isMountedRef.current) {
                    setRoom(rooms[0]);
                }
            } catch (e) {
                rollbar.error("Error getting twilio rooms", e);
            }
        };

        get();
    }, [userId, isMountedRef]);

    return { loading: room ? false : true, room };
};
