import styles from "./index.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export interface Tab<T> {
    /** Unique id for tab */
    id: T;
    /** Displayed tab text */
    label: string;
    /** Component to be displayed when tab is active */
    component: JSX.Element;
}

export interface TabsProps<T> {
    /** Available tabs */
    tabs: Tab<T>[];
    /** Currently displayed tab */
    activeTab: T;
    /** Callback to set the active tab  */
    setActiveTab: (id: T) => void;
    /** Boolean that hides tabs, but displays active tab */
    hideTabs?: boolean;
}

/**
 * A component to format a page with tabs.
 */
export const Tabs = <T extends string>({
    tabs,
    activeTab,
    setActiveTab,
    hideTabs = false
}: TabsProps<T>) => {
    if (hideTabs || tabs.length === 1) {
        return (
            <span>{tabs.find(({ id }) => id === activeTab)?.component}</span>
        );
    }

    return (
        <>
            {!hideTabs && (
                <div className={`tabs ${styles["tabs"]}`}>
                    {tabs.map(({ id, label }) => (
                        <div
                            className={cx(
                                activeTab === id ? styles["tab-on"] : "",
                                `${styles["tab-item"]}`
                            )}
                            key={id as string}
                            onClick={() => setActiveTab(id)}
                        >
                            <span>{label}</span>
                        </div>
                    ))}
                </div>
            )}
            {tabs.map(
                ({ id, component }) =>
                    activeTab === id && (
                        <span key={id as string}>{component}</span>
                    )
            )}
        </>
    );
};
