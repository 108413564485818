import {
    CouponResponse,
    ProrationResponse,
    SilverSunnStripeCreditCard,
    SilverSunnStripeCustomer,
    StripeInvoiceBindingModel,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { Spinner } from "components/spinners/Spinner";
import { displayAmount } from "helpers/stripe";
import { useMemo } from "react";
import {
    StripeCardElementOptions,
    StripePaymentElementChangeEvent
} from "@stripe/stripe-js";
import { CouponCell } from "./CouponCell";
import { calcDiscount } from "utils/js-utils";
import { PaymentMethodCardV2 } from "components/modal/ManagePaymentDetails/PaymentMethodCardV2";
import { GenericMultiSelect } from "components/generic-multiselect";
import { PaymentMethodInput } from "components/inputs/text-input/PaymentMethodInput";
import { useTranslation } from "react-i18next";

export const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    iconStyle: "solid",
    classes: {
        base: "custom-card-element",
        invalid: "custom-card-element--invalid",
        focus: ".custom-card-element--focus",
        webkitAutofill: "custom-card-element--webkit-autofill"
    },
    style: {
        base: {
            iconColor: "rgba(237, 86, 35, 0.5)"
        }
    }
};

export interface CheckoutStepProps {
    selectedPlan: StripePrice;
    unselectPlan: () => void;
    customer: SilverSunnStripeCustomer;
    proration: ProrationResponse;
    invoice: StripeInvoiceBindingModel;
    claimedCoupon: CouponResponse;
    loadingCoupon: boolean;
    couponError: boolean;
    addClaimedCoupon: (coupon: string) => Promise<void>;
    removeClaimedCoupon: () => Promise<void>;
    addingNewPaymentMethod: boolean;
    setAddingNewPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPaymentMethod: SilverSunnStripeCreditCard;
    setSelectedPaymentMethod: React.Dispatch<
        React.SetStateAction<SilverSunnStripeCreditCard>
    >;
    onPaymentMethodChange: (
        id: string,
        event?: Partial<StripePaymentElementChangeEvent>
    ) => void;
    paymentMethodIsRequired?: boolean;
}

export const CheckoutStep = ({
    selectedPlan,
    unselectPlan,
    customer,
    proration,
    invoice,
    claimedCoupon,
    loadingCoupon,
    couponError,
    addClaimedCoupon,
    removeClaimedCoupon,
    addingNewPaymentMethod,
    setAddingNewPaymentMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    onPaymentMethodChange,
    paymentMethodIsRequired = true
}: CheckoutStepProps) => {
    const { t } = useTranslation();

    const intervalText = useMemo<string>(() => {
        if (selectedPlan.IsRecurring) {
            if (selectedPlan.RecurringInterval === "month") {
                return t("subscription-page:monthly-cost");
            } else {
                return t("subscription-page:yearly-cost");
            }
        } else {
            return t("subscription-page:one-time-cost");
        }
    }, [selectedPlan, t]);

    const totalAmount = useMemo<number>(
        () => proration?.TotalAmount ?? invoice?.total ?? 0,
        [invoice, proration]
    );

    const prorationCredit = useMemo<number>(
        () => proration?.ProrationAmount ?? invoice?.applied_balance ?? 0,
        [proration, invoice]
    );

    const amountDue = useMemo<number>(
        () => proration?.AmountDue ?? invoice?.amount_due ?? 0,
        [invoice, proration]
    );

    const displayedTotalAmount = useMemo<number | undefined>(() => {
        if (
            claimedCoupon &&
            claimedCoupon?.ResellerInventoryItem?.ResellerInventory?.Coupon
                ?.Duration === "forever"
        ) {
            if (
                claimedCoupon.ResellerInventoryItem.ResellerInventory.Coupon
                    .AmountOff
            ) {
                return (
                    totalAmount -
                    claimedCoupon.ResellerInventoryItem.ResellerInventory.Coupon
                        .AmountOff
                );
            } else {
                return (
                    totalAmount -
                    totalAmount *
                        (claimedCoupon.ResellerInventoryItem.ResellerInventory
                            .Coupon.PercentOff /
                            100)
                );
            }
        }
        return totalAmount;
    }, [claimedCoupon, totalAmount]);

    const defaultPaymentMethod = useMemo(
        () =>
            paymentMethodIsRequired
                ? customer?.StripeCreditCards.find((cc) => cc.Default)
                : null,
        [customer, paymentMethodIsRequired]
    );

    if (!proration && !invoice) {
        return <Spinner />;
    }

    return (
        <div className={styles["container"]}>
            <span className={styles["plan-name-line"]}>
                <h5>{selectedPlan.Name}</h5>
                <Button type="link" onClick={unselectPlan}>
                    {t("subscription-page:change")}
                </Button>
            </span>
            <div className={styles["pricing-table"]}>
                <div>
                    <div>
                        <div>{intervalText}</div>
                        <div>
                            {displayAmount(displayedTotalAmount, {
                                signed: true,
                                compact: false,
                                roundUp: false
                            })}
                        </div>
                    </div>
                    <div>
                        <div>{t("subscription-page:proration-credit")}</div>
                        <div>
                            {displayAmount(prorationCredit, {
                                signed: true,
                                compact: false,
                                roundUp: false
                            })}
                        </div>
                    </div>
                    <CouponCell
                        couponCode={
                            claimedCoupon?.ResellerInventoryItem.CouponCode
                        }
                        discountAmount={calcDiscount(
                            totalAmount,
                            amountDue,
                            Math.abs(prorationCredit)
                        )}
                        addCoupon={addClaimedCoupon}
                        removeCoupon={removeClaimedCoupon}
                        error={
                            couponError
                                ? t("subscription-page:coupon-error")
                                : undefined
                        }
                        loading={loadingCoupon}
                        hideAmount={
                            claimedCoupon?.ResellerInventoryItem
                                .ResellerInventory.Coupon?.Duration ===
                            "forever"
                        }
                    />

                    <div>
                        <div>{t("subscription-page:total")}</div>
                        <div>
                            {displayAmount(amountDue, {
                                signed: true,
                                compact: false,
                                roundUp: false
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {paymentMethodIsRequired && (
                <>
                    <small>{t("subscription-page:confirm-paragraph")}</small>
                    <div>
                        <GenericMultiSelect
                            items={[
                                ...customer?.StripeCreditCards.map((card) => ({
                                    id: card?.SilverSunnStripeCreditCardId,
                                    component: (
                                        <PaymentMethodCardV2
                                            key={
                                                card.SilverSunnStripeCreditCardId
                                            }
                                            card={card}
                                            variant="compact"
                                        />
                                    )
                                })),
                                {
                                    id: "new-payment-method",
                                    hideSelector: !addingNewPaymentMethod,
                                    component: (
                                        <>
                                            {!addingNewPaymentMethod ? (
                                                <Button
                                                    type="link"
                                                    onClick={() =>
                                                        setAddingNewPaymentMethod(
                                                            true
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        "subscription-page:add-new-card"
                                                    )}
                                                </Button>
                                            ) : (
                                                <PaymentMethodInput
                                                    onChange={(event) =>
                                                        onPaymentMethodChange(
                                                            "new-payment-method",
                                                            event
                                                        )
                                                    }
                                                />
                                            )}
                                        </>
                                    )
                                }
                            ]}
                            previouslySelectedIds={[
                                defaultPaymentMethod?.SilverSunnStripeCreditCardId
                            ]}
                            selectedIdSet={
                                new Set([
                                    addingNewPaymentMethod
                                        ? "new-payment-method"
                                        : selectedPaymentMethod?.SilverSunnStripeCreditCardId
                                ])
                            }
                            handleSelect={(id) => {
                                if (id === "new-payment-method") {
                                    setSelectedPaymentMethod(null);
                                    setAddingNewPaymentMethod(true);
                                } else {
                                    setAddingNewPaymentMethod(false);
                                    setSelectedPaymentMethod(
                                        customer.StripeCreditCards.find(
                                            (c) =>
                                                c.SilverSunnStripeCreditCardId ===
                                                id
                                        )
                                    );
                                    onPaymentMethodChange(id);
                                }
                                onPaymentMethodChange(id, { complete: false });
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
