import { client } from "api/client";
import { useCallback } from "react";
import { useStripeHandlers } from "./useStripeHandlers";

export interface UseMakePassArgs {
    planId: string;
    resellerInventoryItemId: string | undefined;
}

export const useMakePass = ({
    planId,
    resellerInventoryItemId
}: UseMakePassArgs) => {
    const { stripe } = useStripeHandlers();

    return useCallback(
        async (userId: string) => {
            const draftInvoice = await client.stripeInvoices_PostInvoice(
                userId,
                {
                    InvoiceItems: [{ Price: planId, Quantity: 1 }],
                    ResellerInventoryItemId: resellerInventoryItemId
                }
            );

            const finalizedInvoice =
                await client.stripeInvoices_FinalizeInvoice(
                    userId,
                    draftInvoice.id
                );

            if (!finalizedInvoice.paid) {
                const paymentConfirmationResponse =
                    await stripe.confirmCardPayment(
                        finalizedInvoice.payment_intent_secret
                    );

                if (paymentConfirmationResponse.error) {
                    throw paymentConfirmationResponse.error;
                }
            }

            await client.purchaseEntitlements_PostUserPurchaseEntitlement(
                userId,
                finalizedInvoice.id
            );
            return;
        },
        [planId, resellerInventoryItemId, stripe]
    );
};
