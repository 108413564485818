import React, { useCallback, useEffect, useMemo } from "react";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { useClaimCheck } from "hooks/useClaimCheck";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";
import { CatalogSubscriptionPagePasswordSection } from "./CatalogSubscriptionPagePasswordSection/index";
import { useCatalogData } from "hooks/useCatalogData";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { CatalogSubscriptionPageEmailSection } from "./CatalogSubscriptionPageEmailSection";
import {
    Catalog,
    CatalogRequest,
    CatalogResponse
} from "@switcherstudio/switcher-api-client";
import { useCatalogSubscriptionPass } from "views/page-content/CatalogPage/CatalogSubscriptionPage/useCatalogSubscriptionPass";
import { StripeConnectCard } from "components/cards/StripeConnectButton";
import { RecurringPassField } from "components/modal/GatedContentModal/RecurringPassField";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { useBeforeUnload } from "hooks/useBeforeUnload";

export const CatalogSubscriptionPage: React.FC = () => {
    useCatalogAccessBanner();
    const { t } = useTranslation("subscription-page");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");
    const hasPasswordProtectedContentClaim = useClaimCheck(
        "gatedcontent.password"
    );
    const hasEmailProtectedContentClaim = useClaimCheck("gatedcontent.email");

    const { userInfo } = useSelector((state: RootState) => state.user);

    const {
        catalogData: catalog,
        updateCatalog,
        loading
    } = useCatalogData({
        projectId: userInfo?.ProjectId
    });

    const [isEmailGatingEnabled, setIsEmailGatingEnabled] = React.useState(
        catalog?.Details?.IsEmailGatingEnabled ?? false
    );

    const {
        loading: subscriptionLoading,
        details,
        gatedContentStatus,
        isSetup,
        values,
        priceErrors,
        createOrUpdate,
        handlePriceChange,
        recurringPassHasNoChanges,
        entitlementAndPrices,
        handleSubmit,
        catalogHasPrice
    } = useCatalogSubscriptionPass({ catalog });

    useEffect(() => {
        setIsEmailGatingEnabled(
            catalog?.Details?.IsEmailGatingEnabled ?? false
        );
    }, [catalog?.Details?.IsEmailGatingEnabled]);

    const createCatalogRequest = useCallback(
        (catalog: CatalogResponse) => {
            const details = catalog?.Details as unknown as Catalog;
            const apiObject: CatalogRequest = {
                Catalog: {
                    ...details,
                    IsEmailGatingEnabled: isEmailGatingEnabled
                }
            };

            return apiObject;
        },
        [isEmailGatingEnabled]
    );

    const onSubmit = useCallback(() => {
        const catalogRequest = createCatalogRequest(catalog);
        catalogRequest.Catalog.IsEmailGatingEnabled = isEmailGatingEnabled;

        updateCatalog([catalog?.Details?.Id, catalogRequest]);

        if (!recurringPassHasNoChanges) handleSubmit();
    }, [
        catalog,
        isEmailGatingEnabled,
        createCatalogRequest,
        updateCatalog,
        handleSubmit,
        recurringPassHasNoChanges
    ]);

    const onToggleEmail = useCallback(
        (state: boolean | ((prevState: boolean) => boolean)) => {
            setIsEmailGatingEnabled(state);
        },
        [setIsEmailGatingEnabled]
    );

    const subtitleKey = useMemo(() => {
        if (hasPasswordProtectedContentClaim && hasEmailProtectedContentClaim) {
            return t("subscription-page:subscription-options-all");
        } else if (hasPasswordProtectedContentClaim) {
            return t("subscription-page:subscription-options-password");
        } else if (hasEmailProtectedContentClaim) {
            return t("subscription-page:subscription-options-email");
        } else {
            return t("subscription-page:subscription-options-subtitle");
        }
    }, [hasPasswordProtectedContentClaim, hasEmailProtectedContentClaim, t]);

    usePageHeader({
        title:
            hasEmailProtectedContentClaim || hasPasswordProtectedContentClaim
                ? t("subscription-page:gating-options")
                : t("subscription-page:subscription-options"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("subscription-page:subscription-options")
        ],
        subTitle: <Trans i18nKey={t(subtitleKey)} />
    });

    const submitDisabled = useMemo(() => {
        if (!catalog?.Details) return true;

        return isEmailGatingEnabled === catalog?.Details?.IsEmailGatingEnabled;
    }, [isEmailGatingEnabled, catalog?.Details]);

    const shouldShowStripeSection = useMemo(
        () => !subscriptionLoading && !loading && (!isSetup || values),
        [subscriptionLoading, loading, isSetup, values]
    );

    useBeforeUnload(!recurringPassHasNoChanges || !submitDisabled, null, true);

    const saveButton = useMemo(() => {
        return (
            <Button
                type="primary"
                isSubmit
                onClick={onSubmit}
                disabled={recurringPassHasNoChanges && submitDisabled}
            >
                {t("buttons:save-changes")}
            </Button>
        );
    }, [recurringPassHasNoChanges, submitDisabled, onSubmit, t]);

    if (loading) return;

    return (
        <>
            {hasGatedContentAccess && catalog ? (
                <>
                    {hasPasswordProtectedContentClaim && (
                        <CatalogSubscriptionPagePasswordSection />
                    )}
                    {hasEmailProtectedContentClaim && (
                        <CatalogSubscriptionPageEmailSection
                            isEmailGatingEnabled={
                                catalogHasPrice || isEmailGatingEnabled
                            }
                            onToggle={onToggleEmail}
                            disabled={catalogHasPrice}
                        />
                    )}

                    {!isSetup &&
                        (hasEmailProtectedContentClaim ||
                            hasPasswordProtectedContentClaim) &&
                        saveButton}

                    {shouldShowStripeSection && (
                        <div className={styles["stripe-container"]}>
                            {!isSetup && (
                                <StripeConnectCard
                                    details={details}
                                    gatedContentStatus={gatedContentStatus}
                                    variant="Catalog"
                                />
                            )}
                            {isSetup && values && (
                                <RecurringPassField
                                    errors={priceErrors}
                                    type={createOrUpdate}
                                    values={values}
                                    variant="catalog"
                                    onChange={handlePriceChange}
                                    creatorProductId={
                                        entitlementAndPrices
                                            ?.ProductEntitlements?.[0]
                                            ?.ProductId
                                    }
                                />
                            )}
                        </div>
                    )}

                    {isSetup && saveButton}
                </>
            ) : (
                <StudioUpgradeCta />
            )}
        </>
    );
};
