import React, { useEffect, useMemo, useState } from "react";
import { client } from "api/client";
import { TutorialList } from "components/tutorial-videos/TutorialList";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { RootState } from "store/reducers";
import { SubscriptionWidget } from "components/widgets/SubscriptionWidget";
import { CloudAssetsWidget } from "components/widgets/CloudAssetsWidget";
import { VideoUsageWidget } from "components/widgets/VideoUsageWidget";
import { VideoLibraryWidget } from "components/widgets/VideoLibraryWidget";
import { CatalogWidget } from "components/widgets/CatalogWidget";
import { setLoading } from "store/loading/slice";
import { isMobile } from "utils/js-utils";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { EntitlementWidget } from "components/widgets/EntitlementWidget";
import { StripeStatus } from "../platforms/types";
import { useEntitlements } from "hooks/useEntitlements";
import { useClaimCheck } from "hooks/useClaimCheck";
import { VideoPlayerWidget } from "components/widgets/VideoPlayerWidget";
import { ReferralParticipantWidget } from "components/widgets/ReferralParticipantWidget";
import { ShopifyLinkedWidget } from "components/widgets/ShopifyLinkedWidget";
import { AnalyticsWidget } from "components/widgets/AnalyticsWidget";
import { ShopifyExternalAuth } from "@switcherstudio/switcher-api-client";
import { usePageHeader } from "hooks/usePageHeader";
import { Row } from "../../../components/layout/Row";
import { PlayerActivationTrackerBanner } from "components/banners/progress-tracker-banners/PlayerActivationTrackerBanner";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useHasAccess } from "hooks/useHasAccess";
import { useCatalogData } from "hooks/useCatalogData";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { postEvents } from "store/events/thunks";

// will poll constantly if inside component but this works for single load
const _isMobile = isMobile();

export const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const { isInBrowser } = useSwitcherSdk();
    const dispatch = useDispatch<AppDispatch>();
    const isMounted = useIsMountedRef();
    const user = useSelector((state: RootState) => state.user);
    const { events } = useSelector((state: RootState) => state.events);

    const [stripeCustomer, setStripeCustomer] = useState({});
    const [hasActiveSub, setHasActiveSub] = useState<boolean>(false);
    const [cloudAssets, setCloudAssets] = useState({});
    const [hasCloudAssets, setHasCloudAssets] = useState(false);
    const [videoUsage, setVideoUsage] = useState({});
    const [storeCredentials, setStoreCredentials] = useState<
        ShopifyExternalAuth[]
    >([]);
    const [hasVideoAccess, setHasVideoAccess] = useState(false);
    const { entitlements } = useEntitlements(user.userInfo.UserId);
    const hasVideoPlayerAccess = useClaimCheck("videoplayer");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");
    const hasShopifyAccess = useClaimCheck("shopify");
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    const hasAppClaim = useClaimCheck("app");
    const hasAccess = useHasAccess();
    const userHasFirstName: boolean = user?.userInfo?.FirstName !== "";

    const {
        hasCatalogClaim,
        loading,
        catalogData,
        embedCode: catalogEmbedCode,
        shareLink
    } = useCatalogData({ projectId: user?.userInfo?.ProjectId });

    // Fetch usage data
    const { data: usage, loading: videoLibraryDataLoading } = useSwitcherClient(
        (client) => client.cloudRecordings_GetUsage,
        {
            requestImmediately: true,
            hideLoading: true,
            onSuccess: (data) => {
                dispatch(
                    postEvents({
                        "added-video-to-library": data?.TotalRecordings > 0
                    })
                );
            }
        }
    );

    usePageHeader({
        title: userHasFirstName
            ? t("home:welcome", { name: user?.userInfo?.FirstName })
            : t("home:nameless-welcome")
    });

    useEffect(() => {
        async function fetch() {
            if (
                user.userInfo !== undefined &&
                user.userInfo.UserId !== undefined
            ) {
                try {
                    dispatch(setLoading(1));

                    const _stripeCustomer = await client.stripe_GetCustomer();
                    const hasActiveSub =
                        _stripeCustomer?.StripeSubscriptions.some(
                            (s) =>
                                s.Status === StripeStatus.Active &&
                                s.PauseCollection !== null
                        );
                    setStripeCustomer(_stripeCustomer);
                    setHasActiveSub(hasActiveSub);

                    const _cloudAssets =
                        await client.userAssetsUsage_GetUserAssetsUsage(
                            user.userInfo.UserId
                        );
                    setCloudAssets(_cloudAssets);

                    if (hasAccess) {
                        const _videoUsage = await client.twilio_GetRoomUsage(
                            user.userInfo.UserId
                        );
                        setVideoUsage(_videoUsage);
                    }

                    setHasCloudAssets(
                        user.userInfo.FeatureClaims?.indexOf("cloudassets") !==
                            -1
                    );
                    setHasVideoAccess(
                        user.userInfo.FeatureClaims?.indexOf("videochat") !== -1
                    );

                    if (hasShopifyAccess) {
                        const storeCredentials =
                            await client.shopify_GetCredentials();
                        setStoreCredentials(storeCredentials);
                    }

                    dispatch(setLoading(-1));
                } catch (e) {
                    rollbar.error("Home page loading error", e);
                    dispatch(setLoading(-1));
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:load-fail"
                        })
                    );
                }
            }
        }

        fetch();
    }, [dispatch, isMounted, user.userInfo, hasShopifyAccess, hasAccess]);

    const shouldShowActivationTracker = useMemo(() => {
        return isInBrowser && !videoPlayerDisabled && hasGatedContentAccess;
    }, [isInBrowser, videoPlayerDisabled, hasGatedContentAccess]);

    return (
        <>
            {shouldShowActivationTracker && (
                <PlayerActivationTrackerBanner
                    hasCatalogClaim={hasCatalogClaim}
                    catalogEmbedCode={catalogEmbedCode}
                    videoLibraryDataLoading={videoLibraryDataLoading}
                />
            )}

            {!videoPlayerDisabled && hasVideoPlayerAccess && (
                <Row header={t("home:manage-content")}>
                    <VideoLibraryWidget
                        loading={videoLibraryDataLoading}
                        cloudUsage={usage}
                    />
                    {hasCatalogClaim ? (
                        <CatalogWidget
                            enabled={hasCatalogClaim}
                            loading={loading}
                            catalogId={catalogData?.Details?.Id}
                            shareLink={shareLink}
                        />
                    ) : (
                        <VideoPlayerWidget
                            enabled={hasVideoPlayerAccess}
                            hasActiveSub={hasActiveSub}
                        />
                    )}
                    {events?.["added-video-to-library"] && <AnalyticsWidget />}
                </Row>
            )}
            {hasAppClaim && (
                <Row header={t("home:creation-tools")}>
                    <CloudAssetsWidget
                        enabled={hasCloudAssets}
                        assets={cloudAssets}
                    />
                    <VideoUsageWidget
                        userId={user.userInfo?.UserId}
                        usage={videoUsage}
                        enabled={hasVideoAccess}
                    />
                </Row>
            )}
            <Row header={t("home:account-overview")}>
                {!hasActiveSub && entitlements?.length ? (
                    <EntitlementWidget entitlements={entitlements} />
                ) : (
                    <SubscriptionWidget customer={stripeCustomer} />
                )}
                {import.meta.env.VITE_REFERRAL_ENABLE_REFERRALS === "true" &&
                    !user.userInfo?.Roles?.includes("Affiliate") && (
                        <ReferralParticipantWidget />
                    )}
                {hasShopifyAccess && (
                    <ShopifyLinkedWidget
                        enabled={storeCredentials?.length > 0}
                        storeCredentials={storeCredentials}
                    />
                )}
            </Row>

            {hasAppClaim && (
                <TutorialList isMobile={_isMobile} limit={5} offset={3} />
            )}
        </>
    );
};
