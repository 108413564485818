import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AnalyticsRevenuesCards } from "store/view/types";
import { ReorderCardAction } from "./ReorderCardAction";
import { GraphCard } from "components/cards/GraphCard";
import { reorderAnalyticsRevenueCardOrder } from "store/view/slice";
import { Link } from "components/buttons/Link";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable
} from "react-beautiful-dnd";
import { move } from "helpers/arrays";
import { Switch } from "components/utility/Switch";
import { Case } from "components/utility/Case";
import styles from "./index.module.scss";
import { RevenueSummaryResponse } from "@switcherstudio/switcher-api-client";
import { AnalyticsLineGraph } from "components/graphs/AnalyticsLineGraph";
import { useAnalyticsExport } from "hooks/useAnalyticsExport";
import { ExportAction } from "./ExportAction";
import { displayDate } from "helpers/time";
import {
    GatedContentStatus,
    useStripeAccountInfo
} from "hooks/useStripeAccountInfo";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";
import PassEmptyState from "components/empty-state/PassEmptyState";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import { useClaimCheck } from "hooks/useClaimCheck";

export interface RevenueTabProps {
    stripeLink: string;
    metrics: RevenueSummaryResponse;
    metricsLoading?: boolean;
}

export const RevenueTab: React.FC<RevenueTabProps> = ({
    stripeLink,
    metrics,
    metricsLoading
}) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const exportCSV = useAnalyticsExport(metrics?.DateSummary);
    const { gatedContentStatus } = useStripeAccountInfo();
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    const { analyticsRevenueCardOrder } = useSelector(
        (state: RootState) => state.view
    );

    const handleOnDragEnd = useCallback(
        ({ source, destination }: DropResult) => {
            dispatch(
                reorderAnalyticsRevenueCardOrder(
                    move(
                        analyticsRevenueCardOrder,
                        source.index,
                        destination.index
                    )
                )
            );
        },
        [dispatch, analyticsRevenueCardOrder]
    );

    const { link: stripeConnectLink } = useGetStripeConnectLink();

    if (!hasGatedContentAccess) return <StudioUpgradeCta />;

    if (gatedContentStatus !== GatedContentStatus.READY)
        return (
            <PassEmptyState
                title={t("players:gated-content:connect-stripe-account-title")}
                message={t(
                    "players:gated-content:connect-stripe-account-description"
                )}
                linkText={t(
                    "players:gated-content:connect-stripe-account-link-text"
                )}
                linkHref={stripeConnectLink}
            ></PassEmptyState>
        );

    return (
        <div>
            <p>
                {t("analytics-page:revenue-more-details")}{" "}
                <Link to={stripeLink} target="_blank">
                    Stripe Account
                </Link>
            </p>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="analytics-views-tab">
                    {(droppableProvided) => (
                        <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            {analyticsRevenueCardOrder.map((card, index) => (
                                <Draggable
                                    draggableId={card}
                                    key={card}
                                    index={index}
                                >
                                    {(draggableProvided) => (
                                        <div
                                            className={styles["card"]}
                                            {...draggableProvided.draggableProps}
                                            ref={draggableProvided.innerRef}
                                        >
                                            <Switch test={card}>
                                                <Case<AnalyticsRevenuesCards>
                                                    value={
                                                        AnalyticsRevenuesCards.Revenue
                                                    }
                                                >
                                                    <GraphCard
                                                        key={
                                                            AnalyticsRevenuesCards.Revenue
                                                        }
                                                        title={
                                                            t(
                                                                "analytics-page:revenue"
                                                            ) + ":"
                                                        }
                                                        total={
                                                            metrics?.TotalRevenue
                                                        }
                                                        unit="dollars"
                                                        actions={[
                                                            <ExportAction
                                                                key="export"
                                                                onClick={() =>
                                                                    exportCSV(
                                                                        "Revenue",
                                                                        "PurchaseDate",
                                                                        `Switcher-Revenue-Analytics-${displayDate(
                                                                            new Date(),
                                                                            i18n.language,
                                                                            "YYYY-MM-DD"
                                                                        )}.csv`
                                                                    )
                                                                }
                                                                disabled={
                                                                    metricsLoading
                                                                }
                                                            />
                                                        ]}
                                                        handle={
                                                            <ReorderCardAction />
                                                        }
                                                        dragHandleProps={
                                                            draggableProvided.dragHandleProps
                                                        }
                                                        graph={
                                                            <AnalyticsLineGraph
                                                                loading={
                                                                    metricsLoading
                                                                }
                                                                dateSummary={
                                                                    metrics?.DateSummary
                                                                }
                                                                property="Revenue"
                                                                dateProperty="PurchaseDate"
                                                                unit="dollars"
                                                            />
                                                        }
                                                    />
                                                </Case>
                                                <Case<AnalyticsRevenuesCards>
                                                    value={
                                                        AnalyticsRevenuesCards.Sales
                                                    }
                                                >
                                                    <GraphCard
                                                        key={
                                                            AnalyticsRevenuesCards.Sales
                                                        }
                                                        title={
                                                            t(
                                                                "analytics-page:sales"
                                                            ) + ":"
                                                        }
                                                        total={
                                                            metrics?.TotalSales
                                                        }
                                                        actions={[
                                                            <ExportAction
                                                                key="export"
                                                                onClick={() =>
                                                                    exportCSV(
                                                                        "Sales",
                                                                        "PurchaseDate",
                                                                        `Switcher-Sales-Analytics-${displayDate(
                                                                            new Date(),
                                                                            i18n.language,
                                                                            "YYYY-MM-DD"
                                                                        )}.csv`
                                                                    )
                                                                }
                                                                disabled={
                                                                    metricsLoading
                                                                }
                                                            />
                                                        ]}
                                                        handle={
                                                            <ReorderCardAction />
                                                        }
                                                        dragHandleProps={
                                                            draggableProvided.dragHandleProps
                                                        }
                                                        graph={
                                                            <AnalyticsLineGraph
                                                                loading={
                                                                    metricsLoading
                                                                }
                                                                dateSummary={
                                                                    metrics?.DateSummary
                                                                }
                                                                property="Sales"
                                                                dateProperty="PurchaseDate"
                                                            />
                                                        }
                                                        unit="number"
                                                    />
                                                </Case>
                                                <Case<AnalyticsRevenuesCards>
                                                    value={
                                                        AnalyticsRevenuesCards.Customers
                                                    }
                                                >
                                                    <GraphCard
                                                        key={
                                                            AnalyticsRevenuesCards.Customers
                                                        }
                                                        title={
                                                            t(
                                                                "analytics-page:customers"
                                                            ) + ":"
                                                        }
                                                        total={
                                                            metrics?.UniqueCustomers
                                                        }
                                                        loading={metricsLoading}
                                                        graph={
                                                            <AnalyticsLineGraph
                                                                loading={
                                                                    metricsLoading
                                                                }
                                                                dateSummary={
                                                                    metrics?.DateSummary
                                                                }
                                                                property="Customers"
                                                                dateProperty="PurchaseDate"
                                                            />
                                                        }
                                                        actions={[
                                                            <ExportAction
                                                                key="export"
                                                                onClick={() =>
                                                                    exportCSV(
                                                                        "Customers",
                                                                        "PurchaseDate",
                                                                        `Switcher-Customers-Analytics-${displayDate(
                                                                            new Date(),
                                                                            i18n.language,
                                                                            "YYYY-MM-DD"
                                                                        )}.csv`
                                                                    )
                                                                }
                                                                disabled={
                                                                    metricsLoading
                                                                }
                                                            />
                                                        ]}
                                                        handle={
                                                            <ReorderCardAction />
                                                        }
                                                        dragHandleProps={
                                                            draggableProvided.dragHandleProps
                                                        }
                                                    />
                                                </Case>
                                            </Switch>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};
