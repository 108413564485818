import {
    useCallback,
    useEffect,
    useState,
    useContext,
    useRef,
    useMemo
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { setLoading as setStoreLoading } from "store/loading/slice";
import { Client } from "@switcherstudio/switcher-api-client";
import { SwitcherClientContext } from "App";
import { isEqual } from "lodash";
import { setApiResponse } from "store/api/slice";
import { SwitcherApiResponse } from "store/api/types";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { exists } from "helpers/booleans";

type AsyncReturnType<T extends Promise<any>> = T extends Promise<infer R>
    ? R
    : any;

/** The SwitcherClientOptions that are available for updating during invocation of the callback request function */
interface SwitcherClientCallbackRequestUpdateOptions {
    /**
     * Prevents global loading animation from appearing during
     * API request
     */
    hideLoading?: boolean;
}
interface SwitcherClientOptions<X, U, Y>
    extends SwitcherClientCallbackRequestUpdateOptions {
    /**
     * If set to true, will immediately invoke API request on component mount
     */
    requestImmediately?: boolean;
    /**
     * If args change during component lifecycle
     * and "requestImmediately" is set to true
     * a new API request will be triggered
     */
    args?: X;
    /**
     *
     * @returns Void
     *
     * A callback function to call before the API request is made.
     */
    preFetch?: () => void;
    /**
     *
     * @param data The response from the API request
     * @returns Void or Promise of void
     *
     * A callback function to call after the API request succeeds.
     * Can be synchronous or asynchronous
     */
    onSuccess?: (data: U, transformedData: Y) => Promise<void> | void;
    /**
     *
     * @param e Error from API request response
     * @returns Void
     *
     * A callback function to call when the API request fails.
     */
    onError?: (e: any, response?: U) => void;
    /** Delays termination of global loading animation
     *  until onSuccess returns (synchronously or asynchronously)
     *  */
    onSuccessLoading?: boolean;
    transformResponseData?: ({
        originalResponse,
        originalArgs
    }: {
        originalResponse: U;
        originalArgs: X;
    }) => Promise<Y> | Y;
    /**
     * If set to "cache-first",
     * will return cached results if they exist, otherwise will fetch from network
     *
     * If set to "network-only", will fetch from network and ignore cache
     *
     * If set to "cache-and-network", will return any cached value while asynchronously fetching data
     * from api in the background without triggering any loading animation. Will refresh cache upon return
     */
    fetchPolicy?: "cache-first" | "network-only" | "cache-and-network";
}

export function useSwitcherClient<U, X extends any[], Y>(
    /**
     * The Switcher Client method to be invoked.
     * Note: Make sure to pass the method definition
     * rather than the immediately invoked method
     */
    clientMethod: (client: Client) => (...P: X) => Promise<U>,
    options?: SwitcherClientOptions<X, U, Y>
) {
    const [data, setData] = useState<AsyncReturnType<Promise<U>>>(null);
    const [transformedData, setTransformedData] = useState<Y>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(
        options?.requestImmediately ?? false
    );
    const dispatch = useDispatch<AppDispatch>();
    const [isInitialRequest, setisInitialRequest] = useState(true);
    const cancelablePromise = useRef<CancelablePromise>(null);
    const switcherClient = useContext(SwitcherClientContext);
    const argsRef = useRef({ clientMethod, options });
    const updatedArgsRef = useRef<X>();
    const updatedOptionsRef =
        useRef<SwitcherClientCallbackRequestUpdateOptions>();
    const fetchPolicy = useMemo(
        () => options?.fetchPolicy ?? "network-only",
        [options]
    );
    const getNormalizedRequestKey = useCallback(() => {
        return `${argsRef.current?.clientMethod}____${JSON.stringify(
            updatedArgsRef.current ?? argsRef.current?.options?.args
        )}`;
    }, []);

    const apiState = useSelector((s: RootState) => s.api);

    // /** This is the group of api responses for the client method agnostic of args */

    const cachedApiResponse = useMemo(() => {
        const argsStr = updatedArgsRef.current
            ? JSON.stringify(updatedArgsRef.current)
            : JSON.stringify(argsRef.current?.options?.args);
        const clientMethodStr = `${clientMethod}`;
        return apiState[clientMethodStr]?.[argsStr]?.response;
    }, [apiState, clientMethod]);

    const dispatchLoading = useCallback(
        (payload: number) => {
            const hideLoading =
                updatedOptionsRef.current?.hideLoading ??
                argsRef.current.options?.hideLoading;
            if (!hideLoading) {
                dispatch(setStoreLoading(payload));
            }
        },
        [dispatch]
    );

    interface CancelablePromise {
        promise: Promise<U>;
        cancel: (...args: any) => any;
    }

    const makeCancelable = useCallback((promise): CancelablePromise => {
        let _hasCanceled = false;

        const wrappedPromise = new Promise<U>((resolve, reject) => {
            promise.then(
                (val) => (_hasCanceled ? resolve({} as any) : resolve(val)),
                (error) =>
                    _hasCanceled ? reject({ isCanceled: true }) : reject(error)
            );
        });

        wrappedPromise.catch(() => {});

        return {
            promise: wrappedPromise,
            cancel() {
                _hasCanceled = true;
            }
        };
    }, []);

    const handleResponse = useCallback(
        async (response: U, updatedArgs?: X, fromCacheRefresh?: boolean) => {
            setData(response);
            const normalizedKey = getNormalizedRequestKey();

            // if the response is coming from a cache refresh from a previous "cache-and-network" type request
            // we need to not refresh the cache again in order to avoid an endless loop
            if (!fromCacheRefresh) {
                dispatch(
                    setApiResponse(
                        new SwitcherApiResponse(normalizedKey, response)
                    )
                );
            }
            let transformedRes: Y;
            if (!!argsRef.current?.options?.transformResponseData) {
                transformedRes = await Promise.resolve(
                    argsRef.current.options.transformResponseData({
                        originalResponse: response,
                        originalArgs:
                            updatedArgs ?? argsRef.current.options?.args
                    })
                );
                setTransformedData(transformedRes);
            }
            setLoading(false);
            setError(null);
            if (!!argsRef.current.options?.onSuccess) {
                if (argsRef.current.options.onSuccessLoading) {
                    Promise.resolve(
                        argsRef.current.options.onSuccess(
                            response,
                            transformedRes
                        )
                    ).finally(() => dispatchLoading(-1));
                } else {
                    argsRef.current.options.onSuccess(response, transformedRes);
                    dispatchLoading(-1);
                }
            } else {
                dispatchLoading(-1);
            }

            return response;
        },
        [dispatch, dispatchLoading, getNormalizedRequestKey]
    );

    const fetchAndStoreFreshResponse = useCallback(() => {
        argsRef.current
            ?.clientMethod(switcherClient)
            .apply(
                switcherClient,
                updatedArgsRef.current ?? argsRef.current.options?.args
            )
            .then((res) => {
                const normalizedKey = getNormalizedRequestKey();
                setApiResponse(new SwitcherApiResponse(normalizedKey, res));
            });
    }, [getNormalizedRequestKey, switcherClient]);

    const request = useCallback(
        async (
            updatedArgs?: X,
            updatedOptions?: SwitcherClientOptions<X, U, Y>
        ) => {
            updatedArgsRef.current = updatedArgs;
            updatedOptionsRef.current = updatedOptions;

            setLoading(true);

            !!argsRef.current.options?.preFetch &&
                argsRef.current.options?.preFetch();

            if (["cache-first", "cache-and-network"].includes(fetchPolicy)) {
                if (cachedApiResponse && fetchPolicy === "cache-and-network") {
                    // fetches fresh response from server and updates cache silently while cached result is returned
                    fetchAndStoreFreshResponse();
                }

                // return resolved promise on handled response from cached response if it exists
                // otherwise, fall back to fetching the api response
                if (cachedApiResponse) {
                    return Promise.resolve(handleResponse(cachedApiResponse));
                }
            }

            dispatchLoading(1);

            try {
                cancelablePromise.current = makeCancelable(
                    argsRef.current
                        .clientMethod(switcherClient)
                        .apply(
                            switcherClient,
                            updatedArgs?.length
                                ? updatedArgs
                                : argsRef.current.options?.args ?? ([] as X)
                        )
                );
                cancelablePromise.current.promise
                    .then((res) => {
                        handleResponse(res, updatedArgs);
                    })
                    .catch((e) => {
                        if (!e.isCanceled) {
                            setError(e);
                            setLoading(false);
                            if (!!argsRef.current.options?.onError) {
                                argsRef.current.options.onError(
                                    e,
                                    JSON.parse(e.response) as U
                                );
                            }
                        }
                        dispatchLoading(-1);
                    });

                return cancelablePromise.current.promise;
            } catch (e) {
                if (!e.isCanceled) {
                    setError(e);
                    setLoading(false);
                    if (!!argsRef.current.options?.onError) {
                        argsRef.current.options.onError(e);
                    }
                }
                dispatchLoading(-1);
            }
        },
        [
            fetchPolicy,
            dispatchLoading,
            cachedApiResponse,
            fetchAndStoreFreshResponse,
            handleResponse,
            makeCancelable,
            switcherClient
        ]
    );

    // update args on args dependency change and re-call request
    useEffect(() => {
        if (
            !!options &&
            !isEqual(options?.args, argsRef?.current?.options?.args)
        ) {
            request(options.args).catch(() => {});
        }
        argsRef.current.options = options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    useEffect(() => {
        return () => {
            cancelablePromise.current && cancelablePromise.current.cancel();
        };
    }, []);

    const callbackRequest = useCallback(
        (
            updatedArgs?: X,
            updatedOptions?: SwitcherClientCallbackRequestUpdateOptions
        ) => {
            return request(updatedArgs, updatedOptions);
        },
        [request]
    );

    useEffect(() => {
        if (
            !!argsRef.current.clientMethod &&
            argsRef.current.options?.requestImmediately &&
            isInitialRequest
        ) {
            setisInitialRequest(false);
            request().catch(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialRequest]);

    // rerun handleResponse when api response in redux updates async from "cache-and-network" fetch policy
    useEffect(() => {
        if (exists(cachedApiResponse) && fetchPolicy === "cache-and-network") {
            handleResponse(cachedApiResponse, undefined, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cachedApiResponse]);

    // the request function is returned so that it can be used
    // after the hook is called. Useful for event handlers or requests
    // that require variables that might only become available after initial render
    return {
        data,
        error,
        loading,
        dispatchApiRequest: callbackRequest,
        transformedData
    };
}
