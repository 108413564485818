import {
    CouponResponse,
    ProrationResponse,
    SilverSunnStripeCreditCard,
    SilverSunnStripeCustomer,
    StripeInvoiceBindingModel,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useStripeHandlers } from "./useStripeHandlers";
import rollbar from "helpers/rollbar";
import { t } from "i18next";
import { useSwitcherClient } from "./useSwitcherClient";
import { createUrl } from "helpers/url";
import { stringifyBool } from "helpers/booleans";
import { client } from "api/client";

export interface useCheckoutOptions {
    proration: ProrationResponse;
    invoice: StripeInvoiceBindingModel;
    selectedPaymentMethodId: string;
    claimedCoupon: CouponResponse;
    customer: SilverSunnStripeCustomer;
    plan: StripePrice;
    onSuccess?: () => void;
    onError?: (message: string) => void;
}

export const useCheckout = ({
    invoice,
    selectedPaymentMethodId,
    claimedCoupon,
    customer,
    plan,
    onSuccess,
    onError
}: useCheckoutOptions) => {
    const { stripe, elements } = useStripeHandlers();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [paymentError, setPaymentError] = useState<string>("");

    const isSubscription = useMemo<boolean | null>(
        () => plan?.IsRecurring,
        [plan]
    );

    const availablePaymentMethods = useMemo<SilverSunnStripeCreditCard[]>(
        () => customer?.StripeCreditCards,
        [customer?.StripeCreditCards]
    );

    const licensedSubscription = useMemo(
        () =>
            customer?.StripeSubscriptions?.find(
                (s) => s.UsageType === "licensed"
            ),
        [customer]
    );

    const isTrialing = useMemo<boolean>(
        () => userInfo.Status === "trialing",
        [userInfo]
    );

    const isNewSubscription = useMemo(
        () => !licensedSubscription,
        [licensedSubscription]
    );

    const currentSubscriptionId = useMemo(
        () => licensedSubscription?.SilverSunnStripeSubscriptionId,
        [licensedSubscription]
    );

    const returnUrl = useMemo<string>(
        () =>
            createUrl(window.location.origin, {
                pathname: "/subscription",
                searchParams: {
                    planId: plan?.Id,
                    isSubscription: stringifyBool(isSubscription),
                    isTrialing: stringifyBool(isTrialing),
                    isNewSubscription: stringifyBool(isNewSubscription),
                    isNewPaymentMethod: stringifyBool(
                        selectedPaymentMethodId === "new-payment-method"
                    ),
                    currentSubscriptionId,
                    invoiceId: invoice?.id,
                    resellerInventoryItemId:
                        claimedCoupon?.ResellerInventoryItem.Id
                }
            }).toString(),
        [
            claimedCoupon?.ResellerInventoryItem.Id,
            currentSubscriptionId,
            invoice?.id,
            isNewSubscription,
            isSubscription,
            isTrialing,
            plan?.Id,
            selectedPaymentMethodId
        ]
    );

    const { dispatchApiRequest: createSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_CreateSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: updateSubscription } = useSwitcherClient(
        (client) => client.userSubscriptions_UpdateSubscription,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: setPrimaryPaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_SetPrimaryPaymentMethod,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: deletePaymentMethod } = useSwitcherClient(
        (client) => client.userPaymentMethods_DeletePaymentMethod,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const createOrUpdateSubscription = useCallback(async () => {
        if (isNewSubscription) {
            // create new subscription - this is called if the user
            // has NO subscription, which is possible if their previous
            // subscription has been canceled and the cancellation date
            // has passed

            await createSubscription([
                userInfo.UserId,
                {
                    OrganizationId: userInfo.OrganizationId,
                    ResellerInventoryItemId:
                        claimedCoupon?.ResellerInventoryItem?.Id,
                    Plan: plan.Id,
                    Quantity: 1,
                    TrialDays: 0,
                    Source: "SilverSunnDashboard"
                }
            ]);
        } else {
            // update user's current subscription
            await updateSubscription([
                userInfo.UserId,
                currentSubscriptionId,
                {
                    OrganizationId: userInfo.OrganizationId,
                    ResellerInventoryItemId:
                        claimedCoupon?.ResellerInventoryItem?.Id,
                    Plan: plan?.Id
                }
            ]);
        }
    }, [
        claimedCoupon,
        createSubscription,
        currentSubscriptionId,
        isNewSubscription,
        plan,
        updateSubscription,
        userInfo
    ]);

    const createEntitlement = useCallback(async () => {
        let finalizedInvoice = await client.stripeInvoices_FinalizeInvoice(
            userInfo.UserId,
            invoice.id
        );

        const {
            error,
            paymentIntent: { payment_method }
        } = await stripe.confirmPayment({
            elements:
                selectedPaymentMethodId === "new-payment-method"
                    ? elements
                    : undefined,
            clientSecret: finalizedInvoice.payment_intent_secret,
            redirect: "if_required",
            confirmParams: {
                save_payment_method: true,
                payment_method:
                    selectedPaymentMethodId !== "new-payment-method"
                        ? selectedPaymentMethodId
                        : undefined,
                return_url: returnUrl
            }
        });

        if (error) throw error;

        await client.userPaymentMethods_SetPrimaryPaymentMethod(
            userInfo.UserId,
            typeof payment_method === "string"
                ? payment_method
                : payment_method.id
        );

        finalizedInvoice = await client.stripeInvoices_GetInvoice(
            finalizedInvoice.id,
            userInfo.UserId
        );

        if (!finalizedInvoice.paid) {
            throw new Error("Invoice not paid");
        }

        await client.purchaseEntitlements_PostUserPurchaseEntitlement(
            userInfo.UserId,
            finalizedInvoice.id
        );

        if (isTrialing) {
            await client.stripe_DeleteSubscription(
                currentSubscriptionId,
                false
            );
        }
    }, [
        userInfo.UserId,
        invoice,
        stripe,
        selectedPaymentMethodId,
        elements,
        returnUrl,
        isTrialing,
        currentSubscriptionId
    ]);

    const makePayment = useCallback(
        async (paymentMethodId: string): Promise<void> => {
            const { error } = await elements.submit();

            if (error) throw error;

            if (isSubscription) {
                if (paymentMethodId === "new-payment-method") {
                    const {
                        error,
                        setupIntent: { payment_method }
                    } = await stripe.confirmSetup({
                        elements,
                        redirect: "if_required", // if this does redirect is caught at hooks/useCheckoutRedirect:191
                        confirmParams: {
                            return_url: returnUrl
                        }
                    });

                    if (error) throw error;

                    paymentMethodId =
                        typeof payment_method === "string"
                            ? payment_method
                            : payment_method.id;

                    await client.userPaymentMethods_AttachPaymentMethod(
                        userInfo.UserId,
                        paymentMethodId
                    );
                }

                // Set payment as primary if not already
                if (
                    !availablePaymentMethods.find(
                        (method) =>
                            method.SilverSunnStripeCreditCardId ===
                            selectedPaymentMethodId
                    )?.Default
                ) {
                    await setPrimaryPaymentMethod([
                        userInfo.UserId,
                        selectedPaymentMethodId
                    ]);
                }

                await createOrUpdateSubscription();
            } else {
                await createEntitlement();
            }

            onSuccess?.();
        },
        [
            elements,
            availablePaymentMethods,
            isSubscription,
            onSuccess,
            stripe,
            returnUrl,
            selectedPaymentMethodId,
            setPrimaryPaymentMethod,
            userInfo.UserId,
            createOrUpdateSubscription,
            createEntitlement
        ]
    );

    const checkout = useCallback(async () => {
        if (!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);
        setPaymentError("");

        try {
            if (
                !(
                    claimedCoupon?.ResellerInventoryItem?.ResellerInventory
                        ?.IsPaymentMethodRequired ?? true
                )
            ) {
                await createOrUpdateSubscription();
            } else if (selectedPaymentMethodId) {
                await makePayment(selectedPaymentMethodId);
            } else {
                throw new Error(t("errors:no-payment-method"));
            }
        } catch (e) {
            // If a new payment method is used, try to reset the user's default
            // payment method and delete the new failed one.
            if (
                selectedPaymentMethodId &&
                selectedPaymentMethodId !== "new-payment-method"
            ) {
                try {
                    if (availablePaymentMethods.length) {
                        const originalDefault = availablePaymentMethods.find(
                            (c) => c.Default && !c.Expired
                        );
                        if (originalDefault) {
                            await setPrimaryPaymentMethod([
                                userInfo.UserId,
                                originalDefault.SilverSunnStripeCreditCardId
                            ]);
                        }
                    }

                    if (selectedPaymentMethodId !== "new-payment-method") {
                        await deletePaymentMethod([
                            userInfo.UserId,
                            selectedPaymentMethodId
                        ]);
                    }
                } catch {
                    /** eat DeletePaymentMethod request errors */
                }
            }

            if (!!e?.error?.decline_code) {
                rollbar.warning(
                    "Payment collection error with decline code",
                    e
                );
            } else {
                rollbar.error(
                    "Payment collection error without decline code",
                    e
                );
            }

            setPaymentError(e.message || e.error.decline_code);
            onError?.(e.message || e.error.decline_code);
        } finally {
            setIsSubmitting(false);
        }
    }, [
        stripe,
        elements,
        selectedPaymentMethodId,
        claimedCoupon,
        makePayment,
        createOrUpdateSubscription,
        onError,
        availablePaymentMethods,
        setPrimaryPaymentMethod,
        userInfo.UserId,
        deletePaymentMethod
    ]);

    return { checkout, isSubmitting, paymentError };
};
