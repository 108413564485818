import {
    CreatorProduct,
    CreatorProductPrice,
    CreatorProductPricesBindingModel
} from "@switcherstudio/switcher-api-client";
import { v4 as uuidv4 } from "uuid";

export const buildPriceCreateObj = (
    name: string,
    productId: string,
    amount: string,
    isRecurring: boolean,
    recurringInterval?: "month" | "year"
): CreatorProductPricesBindingModel => ({
    Id: uuidv4(),
    Name: name,
    IsActive: true,
    IsPublic: true,
    ProductId: productId,
    Amount: parseFloat(amount) * 100,
    BillingScheme: "per_unit",
    ...(isRecurring && { RecurringUsageType: "licensed" }),
    ...(isRecurring && { IsRecurring: isRecurring }),
    ...(recurringInterval && { RecurringInterval: recurringInterval }),
    ...(isRecurring && { RecurringIntervalCount: 1 })
});

const buildPricesUpdateObj = (
    oldProductPrice: CreatorProductPrice,
    name: string,
    amount: string
): CreatorProductPricesBindingModel =>
    ({
        ...oldProductPrice,
        Name: name,
        Amount: parseFloat(amount) * 100
    }) as CreatorProductPricesBindingModel;

const buildPricesSoftDeleteObj = (
    oldProductPrice: CreatorProductPrice
): CreatorProductPricesBindingModel =>
    ({
        ...oldProductPrice,
        IsActive: false,
        IsPublic: false
    }) as CreatorProductPricesBindingModel;

const buildAndPushRecurringPriceObj = ({
    pricesArray,
    priceSelected,
    oldPrice,
    oldProduct,
    newPriceAmount,
    name,
    recurringInterval
}: {
    pricesArray: CreatorProductPricesBindingModel[];
    priceSelected: boolean;
    oldPrice: CreatorProductPrice;
    oldProduct: CreatorProduct;
    newPriceAmount: string;
    name: string;
    recurringInterval: "year" | "month";
}) => {
    if (priceSelected) {
        if (oldPrice && parseFloat(newPriceAmount) !== oldPrice.Amount) {
            // there was a previous price amount for this interval and now there is a new price amount

            // because it is a new price, the CreatorProductPrice will be
            // soft-deleted and a new CreatorProductPrice will be made to replace the old one
            // if the recurring monthly price is selected but the amount is the same, do nothing
            pricesArray.push(
                buildPricesUpdateObj(oldPrice, name, newPriceAmount)
            );
        } else if (!oldPrice) {
            // there was no previous price amount for this interval and now there is a new price amount

            // so we are actually creating a new price here
            pricesArray.push(
                buildPriceCreateObj(
                    name,
                    oldProduct?.Id,
                    newPriceAmount,
                    true,
                    recurringInterval
                )
            );
        }
    } else if (oldPrice) {
        // there used to be a price amount, but the user has unselected it

        // this will cause a soft delete of the old price without replacing it with a new one
        pricesArray.push(buildPricesSoftDeleteObj(oldPrice));
    }
};

export const buildPricesArray = ({
    recurringMonthlyPriceSelected,
    recurringAnnualPriceSelected,
    recurringMonthlyPrice,
    recurringAnnualPrice,
    oneTimePrice,
    oldProduct,
    oldRecurringMonthlyPrice,
    oldRecurringAnnualPrice,
    oldOneTimePrice,
    name,
    selectedPassType
}: {
    recurringMonthlyPriceSelected: boolean;
    recurringAnnualPriceSelected: boolean;
    recurringMonthlyPrice: string;
    recurringAnnualPrice: string;
    oneTimePrice: string;
    oldProduct: CreatorProduct;
    oldRecurringMonthlyPrice: CreatorProductPrice;
    oldRecurringAnnualPrice: CreatorProductPrice;
    oldOneTimePrice: CreatorProductPrice;
    name: string;
    selectedPassType: "one-time" | "recurring";
}): CreatorProductPricesBindingModel[] => {
    const pricesArray: CreatorProductPricesBindingModel[] = [];

    // build and push recurring monthly price object
    buildAndPushRecurringPriceObj({
        pricesArray,
        priceSelected: recurringMonthlyPriceSelected,
        oldPrice: oldRecurringMonthlyPrice,
        oldProduct,
        newPriceAmount: recurringMonthlyPrice,
        name,
        recurringInterval: "month"
    });

    // build and push recurring annual price object
    buildAndPushRecurringPriceObj({
        pricesArray,
        priceSelected: recurringAnnualPriceSelected,
        oldPrice: oldRecurringAnnualPrice,
        oldProduct,
        newPriceAmount: recurringAnnualPrice,
        name,
        recurringInterval: "year"
    });

    if (
        !recurringMonthlyPriceSelected &&
        !recurringAnnualPriceSelected &&
        selectedPassType === "one-time"
    ) {
        pricesArray.push(
            buildPricesUpdateObj(oldOneTimePrice, name, oneTimePrice)
        );
    }

    return pricesArray;
};
