import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { exists } from "helpers/booleans";

export interface UpgradeButtonProps {
    needsUpgrade?: boolean;
    isLapsedOrPaused?: boolean;
    alternateText?: string;
    alternateRoute?: string;
    buttonStyle?: "btn-primary" | "btn-outline-secondary";
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({
    needsUpgrade = false,
    isLapsedOrPaused = false,
    alternateText,
    alternateRoute,
    buttonStyle = "btn-primary"
}: UpgradeButtonProps) => {
    const { t } = useTranslation("buttons");
    const navigate = useNavigate();

    const buttonText = useMemo<string>(() => {
        if (!needsUpgrade && alternateText) {
            return alternateText;
        }
        return t("upgrade-account").toUpperCase();
    }, [alternateText, needsUpgrade, t]);

    const useNewBillingPage =
        import.meta.env.VITE_USE_NEW_BILLING_PAGE === "true";

    const buttonRoute = useMemo<string>(() => {
        if (!needsUpgrade && alternateRoute) {
            return alternateRoute;
        }
        return useNewBillingPage
            ? "/subscription#openModal"
            : "/subscription/subscribe";
    }, [alternateRoute, needsUpgrade, useNewBillingPage]);

    return (
        <button
            className={`btn ${buttonStyle}`}
            type="button"
            disabled={
                (!needsUpgrade || isLapsedOrPaused) && !exists(alternateRoute)
            }
            onClick={() => navigate(buttonRoute)}
        >
            {buttonText}
        </button>
    );
};
