import React, { useEffect, useState, useCallback } from "react";
import { VideoUsageProps } from "./types";
import VideoChatIcon from "assets/icons/video-chat.svg?react";
import { EnabledIndicator } from "components/widgets/EnabledIndicator";
import { UpgradeButton } from "components/buttons/upgrade-button/UpgradeButton";
import { useTranslation } from "react-i18next";
import { useGetRoom } from "hooks/useGetRoom";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useHasAccess } from "hooks/useHasAccess";
import { AppDispatch } from "store/store";
import { Widget } from "./Widget";

export const VideoUsageWidget: React.FC<VideoUsageProps> = ({
    userId,
    usage,
    enabled
}: VideoUsageProps) => {
    const { t } = useTranslation("widgets");
    const { loading, room } = useGetRoom(userId);
    const dispatch = useDispatch<AppDispatch>();
    const isLapsedOrPaused = useHasAccess(
        (userInfo) =>
            userInfo?.Roles.includes("Paused") ||
            userInfo?.Roles.includes("Lapsed")
    );

    const [clipboardExists] = useState(!!navigator.clipboard);

    const [isEnabled, setIsEnabled] = useState(enabled);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [maxMinutes, setMaxMinutes] = useState(0);
    const [numGuestsText, setNumGuests] = useState("");

    const copyLink = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(room?.Url);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-share-link"
                })
            );
        }
    }, [dispatch, room, clipboardExists]);

    useEffect(() => {
        setIsEnabled(enabled);
        if (usage.TotalSeconds !== undefined)
            setTotalMinutes(Math.floor(usage.TotalSeconds / 60));
        if (usage.MaxSeconds !== undefined)
            setMaxMinutes(Math.floor(usage.MaxSeconds / 60));
        if (usage.MaxParticipants !== undefined)
            setNumGuests(
                `${usage.MaxParticipants - 1} ${
                    usage.MaxParticipants !== 1
                        ? t("remote-guests")
                        : t("remote-guest")
                }`
            );
    }, [usage, enabled, t]);

    return (
        <Widget
            loading={loading}
            icon={<VideoChatIcon />}
            title={t("remote-guests").toUpperCase()}
            details={[
                {
                    title: `${t("widgets:detail1-pt1")} ${t(
                        "widgets:room"
                    )} ${t("widgets:detail1-pt2")}`,
                    buttonText: t("buttons:copy").toUpperCase(),
                    infoText: room?.Url,
                    onClick: isEnabled ? copyLink : undefined,
                    children: !isEnabled ? (
                        <EnabledIndicator enabled={false} />
                    ) : undefined
                },
                {
                    title: t("widgets:number-guests"),
                    infoText: numGuestsText,
                    children: !isEnabled ? (
                        <EnabledIndicator enabled={false} />
                    ) : null
                }
            ]}
            footerSubtitle={
                isEnabled
                    ? t("video-chat-message")
                    : t("upgrade-account-message")
            }
            button={
                <UpgradeButton
                    needsUpgrade={
                        isEnabled === false || maxMinutes <= totalMinutes
                    }
                    isLapsedOrPaused={isLapsedOrPaused}
                    alternateText={t("start-room").toUpperCase()}
                    alternateRoute="/remote-guests"
                    buttonStyle="btn-outline-secondary"
                />
            }
        />
    );
};
