import React, { Dispatch, SetStateAction, useState } from "react";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { CheckboxInput } from "components/inputs/checkbox-input";
import { GatedContentPassFormProps, PricingModalTypes } from ".";
import { Errors } from "hooks/useForm";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Button } from "components/buttons/Button";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useDebounce } from "hooks/useDebounce";
import { ActiveSubscribersTable } from "./ActiveSubscribersTable";

export const RecurringPassField = ({
    values,
    errors,
    onChange,
    setPlayerSelectModalIsOpen,
    buttonText,
    buttonActive,
    loading = false,
    type,
    variant,
    creatorProductId
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (key: string, value: any) => void;
    setPlayerSelectModalIsOpen?: Dispatch<SetStateAction<boolean>>;
    buttonText?: string;
    buttonActive?: boolean;
    loading?: boolean;
    type: PricingModalTypes;
    variant?: "catalog";
    creatorProductId?: string;
}) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const [localValues, setLocalValues] =
        useState<GatedContentPassFormProps>(values);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputId = e?.currentTarget?.id;
        const newValue = e?.currentTarget?.value;

        inputId === "monthly-price-input"
            ? setLocalMonthlyPrice(newValue)
            : setLocalAnnualPrice(newValue);
    };

    const handleToggle = (applyUpdateToNewOnly: boolean) => {
        handleParentChange(
            "recurringApplySubscriptionUpdatesNewOnly",
            applyUpdateToNewOnly
        );
    };

    const [localMonthlyPrice, setLocalMonthlyPrice] = useState<string>(
        values.recurringMonthlyPrice
    );
    const [localAnnualPrice, setLocalAnnualPrice] = useState<string>(
        values.recurringAnnualPrice
    );

    useDebounce(localMonthlyPrice, 1000, {
        onUpdate: (price) => handleParentChange("recurringMonthlyPrice", price)
    });

    useDebounce(localAnnualPrice, 1000, {
        onUpdate: (price) => handleParentChange("recurringAnnualPrice", price)
    });

    const handleParentChange = (key: string, value: any) => {
        setLocalValues({ ...localValues, [key]: value });
        onChange(key, value);
    };

    return (
        <>
            <div
                className={`${mpstyles["pricing-form-expanded-content"]} ${
                    variant ? mpstyles[variant] : ""
                }`}
            >
                <div className={mpstyles["recurring-pass-prices-container"]}>
                    <CheckboxInput
                        checkBoxId="monthly-price-checkbox"
                        inputId="monthly-price-input"
                        inputName={
                            variant === "catalog"
                                ? t("misc:monthly-catalog-price")
                                : t("misc:monthly-price")
                        }
                        checked={localValues.recurringMonthlyPriceSelected}
                        onCheckboxChange={() => {
                            handleParentChange(
                                "recurringMonthlyPriceSelected",
                                !localValues.recurringMonthlyPriceSelected
                            );
                        }}
                        onInputChange={handleChange}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_MONTHLY_PRICE}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        value={localMonthlyPrice}
                        error={errors.recurringMonthlyPrice}
                        disabled={
                            localValues?.recurringMonthlyPriceSelected === false
                        }
                    />
                    <CheckboxInput
                        checkBoxId="annual-price-checkbox"
                        inputId="annual-price-input"
                        inputName={
                            variant === "catalog"
                                ? t("misc:annual-catalog-price")
                                : t("misc:annual-price")
                        }
                        checked={localValues.recurringAnnualPriceSelected}
                        onCheckboxChange={() =>
                            handleParentChange(
                                "recurringAnnualPriceSelected",
                                !localValues.recurringAnnualPriceSelected
                            )
                        }
                        onInputChange={handleChange}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_ANNUAL_PRICE}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        value={localAnnualPrice}
                        error={errors.recurringAnnualPrice}
                        disabled={
                            localValues?.recurringAnnualPriceSelected === false
                        }
                    />
                </div>
                {setPlayerSelectModalIsOpen && buttonText && (
                    <>
                        <hr />
                        <div className={mpstyles["add-to-players-container"]}>
                            <p>
                                {hasCatalogClaim
                                    ? t("buttons:add-to-collections")
                                    : t("buttons:add-to-playlist")}
                            </p>
                            <Button
                                type="badge"
                                disabled={loading}
                                isActive={buttonActive}
                                onClick={() => setPlayerSelectModalIsOpen(true)}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </>
                )}

                {variant === "catalog" && (
                    <>
                        <ActiveSubscribersTable
                            creatorProductId={creatorProductId}
                        />
                        {type === PricingModalTypes.Update && (
                            <>
                                <Toggle
                                    label={t(
                                        "gated-content:subscription-update:update-to-new-only"
                                    )}
                                    on={
                                        localValues.recurringApplySubscriptionUpdatesNewOnly
                                    }
                                    onToggle={() =>
                                        handleToggle(
                                            !localValues.recurringApplySubscriptionUpdatesNewOnly
                                        )
                                    }
                                    className={mpstyles["toggle"]}
                                    reverseLayout={true}
                                />
                                {!localValues.recurringApplySubscriptionUpdatesNewOnly && (
                                    <div
                                        className={
                                            mpstyles[
                                                "recurring-update-subscribers-warning"
                                            ]
                                        }
                                    >
                                        {t(
                                            "gated-content:subscription-update:update-to-new-only-warning"
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
