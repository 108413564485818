import { jsonToCsv } from "helpers/download";
import { useCallback } from "react";
import {
    DateRevenueSummaryResponse,
    DateSummaryResponse
} from "@switcherstudio/switcher-api-client";

export interface UseAnalyticsExportOptions {
    type?: "text/csv";
}

export const useAnalyticsExport = <
    T extends DateSummaryResponse | DateRevenueSummaryResponse,
    K extends keyof T
>(
    dateSummaries: T[],
    { type = "text/csv" }: UseAnalyticsExportOptions = {}
) => {
    return useCallback(
        (property: keyof T, dateProperty: K, filename: string) => {
            let blob: Blob;

            switch (type) {
                case "text/csv":
                    blob = new Blob(
                        [
                            jsonToCsv(
                                dateSummaries.map((d) => {
                                    return {
                                        [dateProperty]: d[dateProperty],
                                        [property]: d[property]
                                    };
                                })
                            )
                        ],
                        { type: "text/csv" }
                    );
            }

            // Creating an object for downloading url
            const url = window.URL.createObjectURL(blob);

            // Creating an anchor(a) tag of HTML
            const a = document.createElement("a");

            // Passing the blob downloading url
            a.setAttribute("href", url);
            a.setAttribute("download", filename);

            // Performing a download with click
            a.click();
        },
        [dateSummaries, type]
    );
};
