import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import {
    ResellerInventoryItem,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import {
    PasswordValidation,
    Errors,
    useForm
} from "../../../../../../hooks/useForm";
import { useStripeHandlers } from "hooks/useStripeHandlers";
import { createUrl } from "helpers/url";
import { setLoading } from "store/loading/slice";
import rollbar from "helpers/rollbar";
import { useOnboardingSetupIntent } from "hooks/useOnboardingSetupIntent";
import { SetupIntentResult } from "@stripe/stripe-js";
import { trackConversion } from "helpers/analyticsHelpers";
import localStorage from "redux-persist/es/storage";
import { useNavigate } from "react-router-dom";

const INTERNATIONAL_PHONE_REGEX =
    /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

export interface CreateAccountFormValues {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    industry: string;
    industryCustom: string;
    emailOptIn: boolean;
}

export const useCreateAccountForm = ({
    clientId,
    tag,
    resellerInventoryItem,
    overrideValues,
    isTrialing,
    plan,
    redirectStatus,
    onSuccess
}: {
    clientId: string | undefined;
    tag: string | undefined;
    resellerInventoryItem: ResellerInventoryItem | undefined;
    overrideValues: CreateAccountFormValues;
    isTrialing: boolean;
    redirectStatus: string;
    plan?: StripePriceAnonymous;
    onSuccess?: () => void;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { elements, stripe } = useStripeHandlers();
    const navigate = useNavigate();

    const { isValidIntent, handleSetupIntent, handleCreateUser } =
        useOnboardingSetupIntent({
            clientId,
            resellerInventoryItemId: resellerInventoryItem?.Id,
            isTrialing,
            plan,
            redirectStatus
        });

    const onSubmit = useCallback(
        async ({
            email,
            password,
            firstName,
            lastName,
            phoneNumber,
            industry,
            industryCustom,
            emailOptIn
        }) => {
            const body = {
                grant_type: "password",
                Email: email,
                FirstName: firstName,
                LastName: lastName,
                Password: password,
                ConfirmPassword: password,
                PhoneNumber: phoneNumber,
                client_id: clientId,
                ResellerInventoryItemId: resellerInventoryItem?.Id,
                Tag: tag,
                Subscribe: !plan,
                Plan: plan,
                Industry: industry,
                IndustryCustom: industryCustom,
                EmailOptIn: emailOptIn
            };
            try {
                dispatch(setLoading({ loading: 1, isFullscreen: true }));

                let setupIntentResult: SetupIntentResult;
                // if a plan is selected, create the setupIntent
                // this will be set if dts is true
                if (plan) {
                    // Setup body in local storage to be consumed if redirect is needed
                    await localStorage.setItem(
                        "onboarding-body",
                        JSON.stringify(body)
                    );
                    setupIntentResult = await stripe.confirmSetup({
                        elements,
                        redirect: "if_required",
                        confirmParams: {
                            return_url: createUrl(window.location.origin, {
                                pathname: "getting-started",
                                searchParams: {
                                    planId: plan.Id,
                                    resellerInventoryItemId:
                                        resellerInventoryItem?.Id,
                                    isTrialing: (
                                        isTrialing || !plan.IsRecurring
                                    ).toString()
                                }
                            }).toString()
                        }
                    });

                    if (setupIntentResult.error) {
                        rollbar.warning(
                            "Error confirming Stripe setup intent",
                            setupIntentResult.error
                        );
                        dispatch(
                            addNotification({
                                type: NotificationType.Danger,
                                message: setupIntentResult.error.message
                            })
                        );
                        return;
                    }

                    if (!(await isValidIntent(setupIntentResult.setupIntent))) {
                        return;
                    }
                    // No redirect was needed, continue;
                    await localStorage.removeItem("onboarding-body");
                }

                const userInfo = await handleCreateUser(body);

                // attach the payment method and subscribe to the selected plan
                if (plan) {
                    await handleSetupIntent(
                        setupIntentResult.setupIntent,
                        userInfo
                    );
                }

                trackConversion(isTrialing, clientId, plan);

                onSuccess && onSuccess();
            } catch (e) {
                let response = JSON.parse(e.response);
                if (
                    response?.ModelState?.Errors?.some((error: string) =>
                        /Email '.*' is already taken\./.test(error)
                    )
                ) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:email-taken",
                            linkComponents: [
                                {
                                    key: "loginLink",
                                    to: "/login",
                                    title: "Login",
                                    onClick: () => navigate("/login")
                                }
                            ]
                        })
                    );
                } else {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:create-account-error"
                        })
                    );
                }
                rollbar.error("Error creating account", e);
            } finally {
                dispatch(setLoading({ loading: -1, isFullscreen: false }));
            }
        },
        [
            clientId,
            resellerInventoryItem?.Id,
            tag,
            plan,
            dispatch,
            handleCreateUser,
            isTrialing,
            onSuccess,
            stripe,
            elements,
            isValidIntent,
            handleSetupIntent,
            navigate
        ]
    );

    return useForm<CreateAccountFormValues>(
        {
            email: overrideValues.email ?? "",
            password: overrideValues.password ?? "",
            firstName: overrideValues.firstName ?? "",
            lastName: overrideValues.lastName ?? "",
            phoneNumber: overrideValues.phoneNumber ?? "",
            industry: overrideValues.industry ?? "",
            industryCustom: overrideValues.industryCustom ?? "",
            emailOptIn: overrideValues.emailOptIn ?? true
        },
        onSubmit,
        (values, pvm?: PasswordValidation) => {
            let errors: Errors = {};
            if (!values.email) {
                errors.email = t("forgot-password:enter-email");
            } else if (!/\S+@\S+\.\S+/.test(values.email)) {
                errors.email = t("errors:invalid-email");
            }

            if (!values.password) {
                errors.password = t("errors:password-required-error");
            } else if (!pvm.hasMinLength) {
                errors.password = t("errors:password-length-error");
            } else if (!pvm.hasLower) {
                errors.password = t("errors:password-lower-error");
            } else if (!pvm.hasUpper) {
                errors.password = t("errors:password-upper-error");
            } else if (!pvm.hasDigit) {
                errors.password = t("errors:password-digit-error");
            } else if (!pvm.hasSpecialChar) {
                errors.password = t("errors:password-special-char-error");
            }

            if (!values.industry) {
                errors.industry = t("errors:industry-required");
            }

            if (
                !values.industry ||
                (values.industry === "Other (Please Specify)" &&
                    !values.industryCustom)
            ) {
                errors.industryCustom = t("errors:custom-industry-required");
            }

            if (
                !values.phoneNumber &&
                !values.phoneNumber.match(INTERNATIONAL_PHONE_REGEX)
            ) {
                errors.phoneNumber = t("errors:phone-required");
            }

            return errors;
        }
    );
};
