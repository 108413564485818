import { AspectRatio, AssetType } from "../types";
import audiofileIcon from "assets/icons/audiofile-icon.png";
import videofileIcon from "assets/icons/videofile-icon.png";
import { isAudioOrVideo } from "./helpers";
import Pica from "pica";

export async function videoThumbnailAsync(
    video: HTMLVideoElement,
    targetWidth: number,
    tag: string,
    orientation: AspectRatio
) {
    const src = await videoToCanvas(video);
    if (src.width > 0) {
        return await drawImage(AssetType.video, src, targetWidth, orientation);
    } else {
        return genericVideoThumbnailAsync(tag, targetWidth, orientation);
    }
}

export async function audioThumbnailAsync(tag: string, targetWidth: number) {
    const src = await dataUrlToCanvas(audiofileIcon);
    return await drawImage(
        AssetType.audio,
        src,
        targetWidth,
        AspectRatio.horizontal,
        tag,
        true
    );
}

export async function genericVideoThumbnailAsync(
    tag: string,
    targetWidth: number,
    orientation: AspectRatio
) {
    const src = await dataUrlToCanvas(videofileIcon);
    return await drawImage(
        AssetType.video,
        src,
        targetWidth,
        orientation,
        tag,
        true
    );
}

export async function imageThumbnailAsync(
    assetType: AssetType,
    base64Image: string,
    targetWidth: number,
    orientation: AspectRatio,
    quality: number = 100,
    imageType?: string
) {
    const src = await dataUrlToCanvas(base64Image);
    return await drawImage(
        assetType,
        src,
        targetWidth,
        orientation,
        undefined,
        undefined,
        quality,
        imageType
    );
}

function videoToCanvas(video: HTMLVideoElement) {
    return new Promise<HTMLCanvasElement>((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx === null) {
            reject(new Error("ctx is null"));
            return;
        }

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas);
    });
}

export async function dataUrlToCanvas(dataUrl: string) {
    return new Promise<HTMLCanvasElement>((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (ctx === null) {
                reject(new Error("ctx is null"));
                return;
            }

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);
            resolve(canvas);
            return;
        };
        img.onerror = (e) => reject(e);
        img.src = dataUrl;
    });
}

function getRatioByOrientation(
    longestLength: number,
    orientation: AspectRatio,
    src: HTMLCanvasElement
) {
    const orientationMap = {
        [AspectRatio.horizontal]: function () {
            const targetHeight = (longestLength / 16) * 9;
            const ratio = Math.min(
                longestLength / src.width,
                targetHeight / src.height
            );

            return {
                targetHeight,
                targetWidth: longestLength,
                ratio,
                width: src.width * ratio,
                height: src.height * ratio
            };
        },
        [AspectRatio.vertical]: function () {
            const targetWidth = (longestLength / 16) * 9;
            const ratio = Math.min(
                longestLength / src.height,
                targetWidth / src.width
            );

            return {
                targetHeight: longestLength,
                targetWidth,
                ratio,
                width: src.width * ratio,
                height: src.height * ratio
            };
        },
        [AspectRatio.auto]: function () {
            const isLandscape = src.width >= src.height;
            const targetResolution = isLandscape
                ? src.width > longestLength
                    ? longestLength
                    : src.width
                : src.height > longestLength
                  ? longestLength
                  : src.height;

            const existingRatio = isLandscape
                ? src.height / src.width
                : src.width / src.height;

            const targetWidth = isLandscape
                ? targetResolution
                : targetResolution * existingRatio;
            const targetHeight = isLandscape
                ? targetResolution * existingRatio
                : targetResolution;

            return {
                targetWidth,
                targetHeight,
                ratio: existingRatio,
                width: targetWidth,
                height: targetHeight
            };
        }
    };

    switch (orientation) {
        case AspectRatio.horizontal:
            return orientationMap[AspectRatio.horizontal]();
        case AspectRatio.vertical:
            return orientationMap[AspectRatio.vertical]();
        case AspectRatio.auto:
            return orientationMap[AspectRatio.auto]();
        default:
            return orientationMap[AspectRatio.horizontal]();
    }
}

async function drawImage(
    assetType: AssetType,
    src: HTMLCanvasElement,
    longestSide: number,
    orientation: AspectRatio,
    tag?: string,
    isBaseThumbnail: boolean = false,
    quality: number = 100,
    imageType?: string
) {
    const pica = new Pica();

    const { width, height, targetHeight, targetWidth, ratio } =
        getRatioByOrientation(longestSide, orientation, src);

    const dest = document.createElement("canvas");
    dest.width = width;
    dest.height = height;

    await pica.resize(src, dest);

    const thumb = document.createElement("canvas");
    thumb.width = targetWidth;
    thumb.height = targetHeight;
    const ctx = thumb.getContext("2d");

    if (ctx === null) {
        throw new Error("ctx null");
    }

    ctx.clearRect(0, 0, thumb.width, thumb.height);

    if (assetType === AssetType.lowerthird) {
        checkerboard(ctx, thumb.width, thumb.height);
    } else {
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, thumb.width, thumb.height);
    }

    const centerShift_x = (targetWidth - dest.width) / 2;
    const centerShift_y =
        assetType === AssetType.lowerthird
            ? targetHeight - height
            : (targetHeight - dest.height) / 2;
    ctx.drawImage(dest, centerShift_x, centerShift_y);

    const image_center_y = centerShift_y + height / 2;
    const center_y = targetHeight / 2;
    const ratio_y = Math.abs(image_center_y - center_y) / center_y;
    const art_y = image_center_y > center_y ? ratio_y * -1 : ratio_y;

    if (isBaseThumbnail) {
        const center_x = targetWidth / 2;
        const measurement =
            getFontSizeToFit(tag, "bold 1px Arial", targetWidth) * 0.95;
        const fontSize = Math.min(measurement, 110);

        ctx.font = `bold ${fontSize}px Arial`;
        ctx.fillStyle = "rgba(75.0, 75.0, 75.0, 1.0)";
        ctx.textAlign = "center";
        ctx.textBaseline = "top";
        // iOS height is 540, y set to 330. 330/540 = .61
        ctx.fillText(tag, center_x + 10, targetHeight * 0.61);
    }

    const thumbnailType =
        imageType ?? (isAudioOrVideo(assetType) ? "image/jpeg" : "image/png");

    return {
        dataURL: thumb.toDataURL(thumbnailType, quality / 100),
        width: thumb.width,
        height: thumb.height,
        art: {
            ratio,
            image: { height, width },
            height: (height / targetHeight).toFixed(6),
            y: art_y.toFixed(6)
        }
    };
}

function getFontSizeToFit(text: string, font: string, maxWidth: number) {
    const ctx = document.createElement("canvas").getContext("2d");
    ctx.font = font;
    return maxWidth / ctx.measureText(text).width;
}

function checkerboard(
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    columns = 14,
    rows = 9,
    backgroundColor = "#666666",
    checkerColor = "#808080"
) {
    const rowHeight = height / rows;
    const columnWidth = width / columns;
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, width, height);

    for (let column = 0; column < columns; column++) {
        for (let row = 0; row < rows; row++) {
            if (
                (row % 2 === 0 && column % 2 === 1) ||
                (row % 2 === 1 && column % 2 === 0)
            ) {
                ctx.fillStyle = checkerColor;
                ctx.fillRect(
                    column * columnWidth,
                    row * rowHeight,
                    columnWidth,
                    rowHeight
                );
            }
        }
    }
}
