import { SwitchProps, Toggle } from "components/inputs/toggle/Toggle";
import { t } from "i18next";
import styles from "./index.module.scss";

export interface CatalogSubscriptionPagePasswordSectionProps
    extends Omit<SwitchProps, "on" | "reverseLayout" | "label"> {
    isEmailGatingEnabled: boolean;
}

export const CatalogSubscriptionPageEmailSection = ({
    isEmailGatingEnabled,
    ...props
}: CatalogSubscriptionPagePasswordSectionProps) => {
    return (
        <div className={styles["email-section"]}>
            <Toggle
                on={isEmailGatingEnabled}
                label={t(
                    "catalog-subscription-page:email-section.email-gating-label"
                )}
                reverseLayout
                {...props}
            />
        </div>
    );
};
