import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CloudState } from "./types";
import { FileItem } from "views/page-content/cloud/upload/types";

export const loading = createSlice({
    name: "cloud",
    initialState: {
        fileItems: []
    } as CloudState,
    reducers: {
        addFileItems: (state, { payload }: PayloadAction<FileItem[]>) => {
            state.fileItems = [...state.fileItems, ...payload];
        },
        addFileItem: (state, { payload }: PayloadAction<FileItem>) => {
            state.fileItems = [...state.fileItems, payload];
        },
        removeFileItem: (state, { payload }: PayloadAction<string>) => {
            state.fileItems = state.fileItems.filter(
                (f) => f.file.name !== payload
            );
        },
        updateFileItem: (
            state,
            { payload }: PayloadAction<Partial<FileItem>>
        ) => {
            state.fileItems = state.fileItems.map((f) => {
                if (f.name !== payload?.name) {
                    return f;
                }

                return {
                    ...f,
                    ...payload
                };
            });
        },
        resetFileItems: (state) => {
            state.fileItems = [];
        }
    }
});
export const {
    addFileItem,
    addFileItems,
    removeFileItem,
    updateFileItem,
    resetFileItems
} = loading.actions;

export default loading.reducer;
