import { TextInput } from "components/inputs/text-input/TextInput";
import { Toggle } from "components/inputs/toggle/Toggle";
import { t } from "i18next";
import React, { useState } from "react";
import styles from "./index.module.scss";

export const CatalogSubscriptionPagePasswordSection = () => {
    const [isPasswordEnabled, setIsPasswordEnabled] = useState(true);
    return (
        <div className={`${styles["password-section"]}`}>
            <Toggle
                on={isPasswordEnabled}
                onToggle={() => setIsPasswordEnabled(!isPasswordEnabled)}
                label={t("subscription-page:password-access")}
                reverseLayout
                disabled={false}
            />
            {isPasswordEnabled && (
                <TextInput
                    id={"password"}
                    label={t("subscription-page:password")}
                    type={"text"}
                    onChange={() => {}}
                    value={""}
                    onClear={() => {}}
                    //This help text needs to be italicized. Probably need a variant tag
                    help={t("subscription-page:password-help")}
                    hideClear={true}
                />
            )}
        </div>
    );
};
